<template>
  <div>
    <div v-if="isShowgrid">
      <va-card title="Advertisement Slot List">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 text-right">
            <va-button
              icon="fa fa-plus"
              class="my-0"
              @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          style="max-height: 90vh;">
          <template slot="actions" slot-scope="props">
            <va-button
              flat
              small
              color="gray"
              icon="fa fa-pencil"
              @click="edit(props.rowData)"
              class="ma-0">
            </va-button>
          </template>
        </va-data-table>
      </va-card>
    </div>
    <div v-if="isShowlist">
      <va-card :title="form_title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Slot Time</span>
            <va-select
              v-if="isCreate"
              v-model.trim="slot_time"
              textBy="time"
              :options="slot_timeArr"
              placeholder="Select Your Slot Time"
              :error="!!SlotTimeErrors.length"
              :error-messages="SlotTimeErrors"
              searchable
            />
            <va-input
              v-if="isUpdate"
              v-model="slot_time"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Duration (specify in seconds)</span>
            <va-input
              v-if="isCreate || isUpdate"
              v-model.trim="duration"
              type="number"
              min="1"
              max="10"
              :error="!!DurationErrors.length"
              :error-messages="DurationErrors"
            />
            <span class="va-form-required-label va-form-label">Repetitive Count</span>
            <va-input
              v-if="isCreate || isUpdate"
              v-model.trim="repetitive_count"
              type="number"
              max="60"
              :error="!!RepetitiveCountErrors.length"
              :error-messages="RepetitiveCountErrors"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createAdvertisement()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateAdvertisement()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import REGEX from '../../i18n/regex_pattern'
import Datepicker from 'vuejs-datepicker'
Vue.use(vueResource)

export default {
  name: 'advertisemnet_slot',
  components: { Datepicker },
  data () {
    return {
      term: null,
      perPage: '10',
      	perPageOptions: ['4', '6', '10', '20'],
      	slot_time: '',
      	SlotTimeErrors: [],
      	slot_timeArr: [],
      	duration: '',
      	DurationErrors: [],
      	repetitive_count: '',
      	RepetitiveCountErrors: [],
      	title: '',
      	TitleErrors: [],
      	left_file: [],
      	bottom_file: [],
      	start_date: new Date(new Date().setDate(1)),
      	StartDateErrors: [],
      	fromDisabledDates: { from: new Date(Date.now()) },
      	available_time: '',
      	available_timeArr: [],
      	AvailableTimeErrors: [],
      isShowgrid: true,
      isShowlist: false,
      isCreate: false,
      isUpdate: false,
      advertisement_list: [],
    }
  },
  computed: {
    formReady () {
      	return !this.SlotTimeErrors.length &&
      				 !this.DurationErrors.length &&
      				 !this.RepetitiveCountErrors.length
    },
    Checkfile_upload () {
      	if (this.left_file[0] && this.bottom_file[0]) {
        	return true
      	} else {
        	Vue.notify({ text: 'Please Upload Images', type: 'error' })
        	return false
      	}
    	},
    fields () {
	      return [{
	        name: 'slot_time',
	        title: 'Slot Time',
	      },
	      {
	        name: 'duration',
	        title: 'Duration',
	      },
	      {
	        name: 'repetitive_count',
	        title: 'Repetitive Count',
	      },
	      {
	        name: '__slot:actions',
	        dataClass: 'text-right',
	      }]
	    },
	    filteredData () {
      	return search(this.term, this.advertisement_list)
	    },
  },
  created () {
    this.getAllAdvertisement()
  },
  methods: {
    getAllAdvertisement () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/advertisement_slot/timings')
      .then(respon => {
        loader.hide()
        this.slot_timeArr = respon.body
	      this.$http.get(config.menu.host + '/advertisement_slot')
	      .then(response => {
	        loader.hide()
	        this.advertisement_list = response.body
	        this.isshowgrid = true
	      }, error => {
	        loader.hide()
	        if (error && error.body) {
	          Vue.notify({ text: error.body, type: 'error' })
	        }
	      })
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
    },
    createAdvertisement () {
      this.SlotTimeErrors = this.slot_time ? [] : ['Slot Time is required']
      this.DurationErrors = this.duration ? [] : ['Duration is required']
      this.RepetitiveCountErrors = this.repetitive_count ? [] : ['Repetitive Count is required']

      if ((Number(this.duration) < 5) || (Number(this.duration) > 60)) {
        return Vue.notify({ text: 'Select Duration between 5sec to 60sec', type: 'error'})
      }
      if ((Number(this.repetitive_count) < 1) || (Number(this.repetitive_count) > 10)) {
        return Vue.notify({ text: 'Select Repetitive Count between 1 to 10', type: 'error' })
      } 

      if (!this.formReady) {
        	return
      	}

      	var payload = {
	       slot_time: this.slot_time.time,
	       duration: this.duration,
	       repetitive_count: this.repetitive_count,
	      }

	      const loader = Vue.$loading.show({ width: 40, height: 40 })
	      this.$http.post(config.menu.host + '/advertisement_slot', payload)
	        .then(responseData => {
	          loader.hide()
	          Vue.notify({ text: responseData.body, type: 'success' })
	          this.list()
	          this.getAllAdvertisement()
	        }, err => {
	          loader.hide()
	          if (err && err.body) {
	            Vue.notify({ text: err.body, type: 'error' })
	          }
	        })
    },
    updateAdvertisement () {
      this.DurationErrors = this.duration ? [] : ['Duration is required']
      this.RepetitiveCountErrors = this.repetitive_count ? [] : ['Repetitive Count is required']


      if (!this.formReady) {
        	return
      	}

      	var payload = {
	       slot_time: this.slot_time.time,
	       duration: this.duration,
	       repetitive_count: this.repetitive_count,
	      }

	      const loader = Vue.$loading.show({ width: 40, height: 40 })
	      this.$http.put(config.menu.host + '/advertisement_slot/' + this.ad_slot_id, payload)
	        .then(responData => {
	          loader.hide()
	          Vue.notify({ text: responData.body, type: 'success' })
	          this.list()
	          this.getAllAdvertisement()
	        }, err => {
	          loader.hide()
	          if (err && err.body) {
	            Vue.notify({ text: err.body, type: 'error' })
	          }
	        })
    },

    edit (row) {
	      this.form_title = 'Update Advertisement Slot'
	      this.slot_time = row.slot_time
	      this.ad_slot_id = row.ad_slot_id
	      this.SlotTimeErrors = []
	      this.duration = row.duration
	      this.DurationErrors = []
	      this.repetitive_count = row.repetitive_count
	      this.RepetitiveCountErrors = []
	      this.isShowgrid = false
	      this.isShowlist = true
	      this.isCreate = false
	      this.isUpdate = true
	    },
    add () {
      this.form_title = 'Create Advertisement Slot'
	      this.slot_time = ''
	      this.SlotTimeErrors = []
	      this.duration = ''
	      this.DurationErrors = []
	      this.repetitive_count = ''
	      this.RepetitiveCountErrors = []
	      this.isShowlist = true
	      this.isShowgrid = false
	      this.isUpdate = false
	      this.isCreate = true
	    },
	    list () {
	    	this.getAllAdvertisement()
       	this.isShowgrid = true
      	this.isShowlist = false
    	},

    search: function (term) {
      	this.term = term
    	},
  },
}
</script>

<style lang="scss">
</style>
